// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-tags-index-tsx": () => import("./../../src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-list-article-tsx": () => import("./../../src/templates/list-article.tsx" /* webpackChunkName: "component---src-templates-list-article-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

